<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs"/>
    <el-page-header @back="$router.go(-1)" :content="`${ this.from === 'sales' ? '销售' : '核销' }详情`"></el-page-header>
    <div class="content-box">
      <el-table :loading="loading" :data="tableData" :stripe="true" border fit>
        <el-table-column label="序号">
          <template slot-scope="scope">{{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column prop="orderNo" label="订单编号"></el-table-column>
        <el-table-column prop="productName" label="商品名称"></el-table-column>
        <el-table-column prop="totalAmount" label="订单金额"></el-table-column>
        <el-table-column label="姓名"></el-table-column>
        <el-table-column label="手机号"></el-table-column>
        <el-table-column prop="createTime" :label="`${ this.from === 'sales' ? '下单' : '核销' }时间`"></el-table-column>
        <el-table-column prop="awardAmount" label="佣金"></el-table-column>
        <el-table-column prop="srcHotelName" label="供应酒店"></el-table-column>
        <pagination :total="total" :page-size="limit" @handleSizeChangeSub="changePageNum" @handleCurrentChangeSub="handleCurrPage"/>
      </el-table>
    </div>
  </div>
</template>
<script>
import { getMarketingList } from '@/api/shop/distribution/distribution';
export default {
  data () {
    return {
      crumbs: new Map([
        ['特惠商城'], ['分销中心'], ['渠道分销', '/channel_distribution']
      ]),
      loading: true,
      tableData: [],
      limit: 1,
      page: 1,
      total: 0,
      id: '',
      from: '',
      dateStr: ''
    }
  },
  created() {
    Object.assign(this, this.$route.query)
    this.crumbs.set(`${ this.from === 'sales' ? '销售' : '核销' }数据`, `/channel_distribution/statistics`)
        .set(`${ this.from === 'sales' ? '销售' : '核销' }详情`, '')
  },
  mounted() {
    this.limit = sessionStorage.getItem('pageSize') * 1
    this.getSalesOrWriteOff()
  },
  methods: {
    // 获取销售/核销详情
    getSalesOrWriteOff() {
      const query = `?limit=${ this.limit }&page=${ this.page }`,
            params = { dateStr: this.dateStr };
      getMarketingList(query, this.from, params).then(({ success, records, total }) => {
        if (!success) return;
        this.tableData = records
        this.total = total
      })
    },
    // 改变每页数
    changePageNum(num) {
      this.limit = num
      this.getSalesOrWriteOff()
    },
    // 改变当前页
    handleCurrPage(num) {
      this.page = num
      this.getSalesOrWriteOff()
    }
  }
}
</script>
