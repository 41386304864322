import request from "@/api/service";
import qs from 'qs'

const api = {
   page: '/goods/distribution/getPage',
   add: '/goods/distribution/add',
   edit: '/goods/distribution/edit',
   delete: '/goods/distribution/delete',
   hotelPage: '/hotel/getPage',
   qrCode: '/goods/distribution/generate',
   marketingStat: '/order/mallMarketing/marketingStat',
   marketingUseStat: '/order/mallMarketing/marketingUseStat',
   salesDetail: '/order/mallMarketing/marketingList',
   writeOffDetail: '/order/mallMarketing/marketingUseList'
};

export function getPage(params) {
   return request({
      url: api.page,
      method: 'GET',
      params
   })
}

export function saveChannel(params, action) {
   return request({
      url: api[action],
      method: 'POST',
      data: params
   })
}

export function delChannel(query) {
   return request({
      url: api.delete + query,
      method: 'POST',
   })
}

export function getHotelPage(query, params = {}) {
   return request({
      url: api.hotelPage + query,
      method: 'POST',
      data: params
   })
}

export function generateQrCode(params) {
   return request({
      url: api.qrCode,
      method: 'POST',
      data: qs.stringify(params)
   })
}

export function getMarketingStat(query, params) {
   return request({
      url: api.marketingStat + query,
      method: 'POST',
      data: params
   })
}

export function getMarketingUseStat(query, params) {
   return request({
      url: api.marketingUseStat + query,
      method: 'POST',
      data: params
   })
}

export function getMarketingList(query, from, params = {}) {
   return request({
      url: api[from + 'Detail'] + query,
      method: 'POST',
      data: params
   })
}
